form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    border: none;
}

.hs-form-required {
    display: "none";
}

.hs-form input[name="email"] {
    padding: 10px 10px;
    margin-top: 10px;
    color: #757575;
    height: 2.5rem;
    border-width: 1px;
    border-color: #9e9e9e;
    border-style: solid;
    border-radius: 8px;
    width: 100%;
}

.hs_error_rollup {
    display: none;
}

.hs-form input[type="submit"] {
    margin-left: 6px;
    margin-top: 36px;
    background-color: #007fff;
    height: 2.5rem;
    color: white;
    font-weight: 500;
    padding: 10px 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.hs-form label {
    font-weight: 500;
    font-size: 17px;
}

.hs-error-msg {
    color: red;
}
